h1,
h2,
h3,
h4,
h5 {
    font-weight: 300;
}

h6 {
    font-weight: 400;
}

h2 {
    margin-bottom: 1.25rem;
}

h6 {
    @include media-query(min-xl) {
        font-size: 1.125rem;
    }
}
