.btn {
    border: 2px solid $accent;
    color: $white;
    background: $primary-dark;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 1rem;
    padding: .375rem 1.5rem;
    transition: all 200ms $transition-timing;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:hover {
        background: $accent;
        color: $primary-dark;
        box-shadow: 0 0 2rem rgba($accent-alt, .85);
    }
}
