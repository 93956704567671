.site-header {
    position: relative;
    z-index: 9999;
}

.navbar {
    transition: background-color 300ms linear 400ms, box-shadow 300ms linear 400ms;
    padding: .5rem;

    @include media-query(min-sm) {
        padding: .5rem 1rem;
    }

    .navbar-toggler {
        border-color: transparent;
        padding: .5rem;
    }

    .navbar-brand {
        width: 229px;
        transition: width 1ms linear 700ms;

        @include media-query(max-md) {
            margin: 0 auto;
        }

        .logo {
            display: block;
            width: 229px;
            height: 60px;
            transition: all 300ms linear 400ms;

            .logo__name,
            .logo__tagline {
                transition: opacity 300ms linear 400ms;
            }
        }
    }

    .navbar-collapse {
        @include media-query(max-md) {
            display: none;
        }
    }

    .navbar-nav {
        align-items: flex-end;

        @include media-query(min-lg) {
            align-items: center;
        }

        svg {
            display: inline-block;
            vertical-align: middle;
            height: 1.25rem;
        }
    }

    &.navbar-dark {
        .navbar-nav {
            .nav-link {
                color: $white;
                padding: .5rem 1rem .5rem 1rem;
                transition: all 250ms $transition-timing;

                @include media-query(min-lg) {
                    color: rgba($white, .75);
                    padding: .5rem 1rem;
                }

                &:hover {
                    color: $white;

                    svg {
                        fill: $white;
                    }
                }
            }

            .nav-item--social {
                .nav-link {
                    @include media-query(min-lg) {
                        padding: .5rem;
                    }
                }
            }

            .nav-item--social-first {
                @include media-query(min-lg) {
                    margin-left: .5rem;
                }
            }

            svg {
                fill: $white;
                transition: all 250ms $transition-timing;

                @include media-query(min-lg) {
                    fill: rgba($white, .75);
                }
            }
        }
    }

    &.navbar--scrolled {
        @include media-query(min-lg) {
            background-color: rgba($primary-dark, .95);
            box-shadow: 0 .125rem 1rem rgba($primary-dark, .45);

            .navbar-brand {
                width: 40px;
                overflow: hidden;

                .logo {
                    width: 153px;
                    height: 40px;

                    .logo__name,
                    .logo__tagline {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
