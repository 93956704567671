.home {
    position: relative;

    .navbar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        @include media-query(min-lg) {
            z-index: 999;
            position: fixed;
        }
    }

    .hero {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        backface-visibility: hidden;
    }
}
