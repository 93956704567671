.site-footer {
    padding: 1rem 0;
    position: relative;

    @include media-query(min-lg) {
        padding: 3.5rem 0 1rem;
    }
}

.footer-brand {
    display: inline-block;
    padding: .5rem;
}

#floaters {
    position: absolute;
    bottom: .25rem;
    left: 0;
    width: 100%;
    height: 120vh;
    z-index: -1;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, $primary 0, rgba($primary, 0) 75%);
    }
}
