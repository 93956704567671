#portfolio {
    overflow: hidden;

    .col-xs-12 {
        padding: 0 30px;

        @include media-query(min-sm) {
            padding: 0 15px;
        }
    }
}

.portfolio-item {
    display: block;
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 1rem;
    margin-bottom: 30px;

    @include media-query(min-xl) {

        &:hover,
        &:focus {
            .portfolio-item__title {
                opacity: 0;
            }
        }
    }
}

.portfolio-item__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(1);
    transition: transform 250ms;
    z-index: 1;

    @include media-query(min-xl) {
        transition: all 250ms;
    }
}

.portfolio-item__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba($white, .85);
    padding: .75rem .5rem;
    color: $primary;
    transition: all 250ms;
    z-index: 2;
    font: 400 1.25rem/1 'proxima-nova', sans-serif;
    text-align: center;
    box-shadow: 0 0 1.5rem rgba($black, .15);
}

#portfolio-modal {
    padding-right: 0 !important;

    .modal-dialog {
        transform: translate(0, 0);
    }

    .modal-content {
        border-radius: 1rem;
        border: 0;
        background: $primary-dark;
        overflow: hidden;
        font-size: .9rem;
    }

    a {
        display: inline-block;
        color: $accent;
        position: relative;
        font-weight: 600;
        transition: all 200ms;

        &::before {
            content: '';
            width: calc(100% + 4px);
            height: 100%;
            background: rgba($accent, .15);
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: 0;
            transition: all 200ms;
        }

        &:hover,
        &:focus {
            color: $accent;
            text-decoration: none;
            text-shadow: 1px 1px 0 $primary-dark, -2px 1px 0 $primary-dark;

            &::before {
                height: 2px;
                top: auto;
                bottom: 4px;
                background: $accent;
            }
        }

        &:focus:active {
            color: $accent-alt;

            &::before {
                background: $accent-alt;
            }
        }
    }
}

.portfolio-modal__left {
    padding: 0;
    background: $black;

    img {
        display: block;
        width: 100%;
    }
}

.portfolio-modal__right {
    padding: 1rem;

    @include media-query(min-lg) {
        padding: 2rem 1rem 1rem 2rem;
    }

    h5 {
        @include media-query(min-lg) {
            margin-top: 1rem;
        }
    }

    .icon-list {
        margin-bottom: 0;
    }
}

#portfolio-modal__link {
    margin: 1rem 0;
}
