@import '../modules/colors';
@import '../modules/fonts';
@import '../modules/mixins';

html {
    font-size: 18px;
}

body {
    background-color: $primary-dark;
    color: $white;
    position: relative;
    right: 0;
    transition: all 250ms $transition-timing;
    overflow-x: hidden;

    &.modal-open {
        padding-right: 0 !important;

        @include media-query(min-lg) {
            overflow-y: auto;
        }

        .navbar,
        .navbar--scrolled {
            position: absolute;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}

small {
    font-size: 75%;
}

main {
    padding-top: 3.5rem;

    a:not(.btn):not(.portfolio-item) {
        display: inline-block;
        color: $accent;
        position: relative;
        font-weight: 600;
        transition: all 200ms;

        &::before {
            content: '';
            width: calc(100% + 4px);
            height: 100%;
            background: rgba($accent, .15);
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: -1;
            transition: all 200ms;
        }

        &:hover,
        &:focus {
            color: $accent;
            text-decoration: none;
            text-shadow: 1px 1px 0 $primary-dark, -2px 1px 0 $primary-dark;

            &::before {
                height: 2px;
                top: auto;
                bottom: 4px;
                background: $accent;
            }
        }

        &:focus:active {
            color: $accent-alt;

            &::before {
                background: $accent-alt;
            }
        }
    }
}

a.disabled {
    pointer-events: none;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .container > & {
        @include media-query(max-sm) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}

.content {
    position: relative;
    top: 100vh;
    z-index: 99;
    background: linear-gradient(to bottom, $primary-dark 0, $primary 40rem);
}

.content-section {
    padding: 3.5rem 0;
    min-height: 65vh;
}

.content-section__intro {
    span {
        + span {
            @include media-query(min-sm) {
                margin-left: 1rem;
            }
        }
    }
}

.panel {
    background: $primary-dark;
    padding: 1.25rem 1rem;
    margin: 0 -15px;

    @include media-query(min-sm) {
        margin: 0;
    }

    @include media-query(min-lg) {
        padding: 1.25rem 1rem 1rem;
    }

    .btn {
        @include media-query(min-lg) {
            margin: 1rem 2rem 0 0;
        }
    }
}

.icon {
    display: inline-block;
    vertical-align: middle;
    width: 1.25rem;
    height: 1.25rem;
    fill: $white;
    position: relative;
    top: -2px;

    .fill-accent {
        fill: $accent;
    }

    .fill-error {
        fill: $error;
    }
}

.icon-list {
    color: $grey;
    font-size: .889rem;
    margin: 1rem 0 2.25rem;

    @include media-query(min-lg) {
        margin: 1rem 0;
    }

    li {
        margin-bottom: .75rem;
    }
}

.icon-list__icon {
    margin-right: .25rem;
}

.contact-list {
    margin-top: 3rem;

    li {
        margin: 0 0 2rem;
    }

    .icon {
        margin-right: .5rem;
    }
}

.modal-lg {
    max-width: calc(100% - 30px);
    width: 1110px;
    margin: 1rem auto;

    @include media-query(min-lg) {
        margin: auto;
    }

    .close {
        position: absolute;
        z-index: 2;
        opacity: 1;
        top: 0;
        right: 0;
        padding: .5rem;
        background: rgba($black, .15);
        font-size: 0;
        display: block;

        @include media-query(min-lg) {
            background: none;
            opacity: .5;

            &:hover {
                opacity: .8;
            }
        }

        svg {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            fill: $white;
        }
    }
}

.modal-backdrop.show {
    opacity: .75;
}
