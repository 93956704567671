.hero {
    position: relative;
    overflow: hidden;

    .container {
        position: relative;
        z-index: 1;
    }
}

.hero__layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    backface-visibility: hidden;
    transform-origin-x: 50%;
}

.hero__layer--bgcolor {
    background: linear-gradient(to bottom, $primary-dark 0, $primary 30%, $primary-light 110%);
    background-attachment: fixed;
}

.hero__layer--stars {
    canvas {
        position: fixed;
        top: 0;
        left: 0;
    }
}

.hero__layer--trees-back {
    background: url('../img/trees-back2.svg') 0 100%;
    background-repeat: no-repeat;
    background-size: 200%;
    width: calc(100% + 10px);
    margin-left: -5px;

    @media only screen and (min-width: 320px) and (max-width: 568px) and(orientation: landscape) {
        background-size: 150%;
    }

    @include media-query(min-sm) {
        background-size: 150%;
        background-position: 60% 100%;
    }

    @include media-query(min-md) {
        background-size: 125%;
    }

    @include media-query(min-lg) {
        background-size: contain;
        background-position: 50% 100%;
    }
}

.hero__layer--content {
    display: flex;
    align-items: center;

    h1 {
        line-height: 1;

        @include media-query(min-lg) {
            line-height: 1.2;
        }

        > span {
            display: block;

            &:nth-child(1) {
                font-size: 1rem;
            }

            &:nth-child(3) {
                font-size: 1.333rem;
            }

            &#typed,
            &.typed-cursor {
                display: inline-block;
                vertical-align: middle;
            }

            &#typed {
                font-size: 1.666rem;
                font-weight: 700;
            }
        }
    }
}

.typed-cursor {
    opacity: 1;
    animation: typedjsBlink .7s infinite;
    font-size: 0;
    width: .125rem;
    height: 1.75rem;
    background: $grey;
    margin-left: .25rem;
}

@keyframes typedjsBlink {
    50% {
        opacity: 0;
    }
}

.hero__layer--trees-front {
    background: url('../img/trees-front.svg') 50% 100%;
    background-repeat: no-repeat;
    background-size: 250%;
    width: calc(100% + 10px);
    margin-left: -5px;

    @media only screen and (min-width: 320px) and (max-width: 568px) and(orientation: landscape) {
        background-size: 150%;
    }

    @include media-query(min-sm) {
        background-size: 150%;
    }

    @include media-query(min-lg) {
        background-size: contain;
    }

    &::after {
        content: '';
        width: 100%;
        height: 50vh;
        background: $primary-dark;
        position: absolute;
        top: 100%;
        left: 0;
    }
}
